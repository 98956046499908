import Customer from "./customer";
import SaleItem from "./saleItem";
import EconActivity from "./econActivity";
import EDocument from "./edocument";
import store from '../store'

const Sale = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null
            this.proformaId = null
            this.serialNumber = null
            this.econActivity = null;
            this.customer = new Customer();
            this.items = [];
            this.createdAt = null;
            this.closedAt = null;
            this.creditDays = 0;
            this.total = 0.0;
            this.discount = 0.0;
            this.sub0 = 0.0;
            this.sub12 = 0.0;
            this.tax = 0.0;
            this.taxPercentage = store.getters.business.taxPercentage;
            this.edoc = null;
            this.editable = true;
            this.paymentMethod = {name: 'Efectivo', code: 'CASH'};
            this.collectStatus = null; // To reference if is paid or not
            this.edocStatus = null; // To know the edoc status if emitted
            this.observations = null;
        } else {
            this.id = object.id;
            this.proformaId = null;
            this.serialNumber = object.serialNumber;
            this.econActivity = new EconActivity(object.activity);
            this.customer = new Customer(object.customer);
            this.createdAt = new Date(Date.parse(object.createdAt));
            this.closedAt = new Date(Date.parse(object.closedAt));
            this.creditDays = object.creditDays;
            this.total = parseFloat(object.total);
            this.discount = parseFloat(object.discount) || 0.0;
            this.sub0 = parseFloat(object.base0);
            this.sub12 = parseFloat(object.base12);
            this.tax = parseFloat(object.tax);
            this.taxPercentage = object.taxPercentage || store.getters.business.taxPercentage;
            this.editable = object.editable;
            this.paymentMethod = object.paymentMethod;
            this.collectStatus = object.status; // To reference if is paid or not
            this.edocStatus = object.edocStatus; // To know the edoc status if emitted
            this.observations = object.observations;

            // Parse items if exists
            if (object.items !== undefined && object.items !== null && object.items.length > 0) { 
                this.items = object.items.map(item => new SaleItem(item)); 
            }

            // Parse edoc if exists
            if (object.edoc !== undefined && object.edoc !== null) {
                this.edoc = new EDocument(object.edoc);
            } else { this.edoc = null }
        }
    }
    get createdAtFormatted() {
        var options = { year: 'numeric', month: 'numeric', day: 'numeric'};
        return this.createdAt.toLocaleDateString('es-EC', options) + " - " + this.createdAt.toLocaleTimeString('en-US');
    }
    get dueDate() {
        if (this.creditDays > 0) {
            var dueDate = (this.createdAt !== null) ? new Date(this.createdAt) : new Date();
            dueDate.setDate(dueDate.getDate() + parseInt(this.creditDays));
            return dueDate;
        }
        return null;
    }
    get dueDateFormatted() {
        if (this.dueDate !== null) {
            var options = { year: 'numeric', month: 'numeric', day: 'numeric'};
            return this.dueDate.toLocaleDateString('es-EC', options);
        }
        return null;
    }
    get isPaid() {
        return (this.collectStatusReadable === 'COBRADA');
    }
    get state() {
        if (this.editable === true) { return "GUARDADA" }
        else if (this.edoc === null) { return "ARCHIVADA" }
        else if (this.edoc.nulled === true) { return "ANULADA" }
        else if (this.edoc.authorized === true) { return "FACTURADA" }
        else { return "POR REPORTAR" }
    }
    get stateBadgeColor() {
        if (this.editable === true) { return "blue" }
        else if (this.edoc === null) { return "gray" }
        else if (this.edoc.nulled === true) { return "red" }
        else if (this.edoc.authorized === true) { return "green" }
        else { return "yellow" }
    }
    get edocState() {
        if (this.editable === true) { return "GUARDADA" }
        else if (this.edocStatus === "none") { return "ARCHIVADA" }
        else if (this.edocStatus === "nulled") { return "ANULADA" }
        else if (this.edocStatus === "authorized") { return "FACTURADA" }
        else if (this.edocStatus === "received") { return "POR AUTORIZAR" }
        else { return "POR REPORTAR" }
    }
    get edocStateBadgeColor() {
        if (this.editable === true) { return "blue" }
        else if (this.edocStatus === "none") { return "gray" }
        else if (this.edocStatus === "nulled") { return "red" }
        else if (this.edocStatus === "authorized") { return "green" }
        else if (this.edocStatus === "received") { return "yellow" }
        else { return "orange" }
    }
    get collectStatusReadable() {
        if (this.edocStatus === "nulled") { return "NO APLICA" }
        if (this.collectStatus === "PAID") { return "COBRADA"}
        if (this.collectStatus === "PENDING" && !this.editable && this.dueDate !== null && this.dueDate <= new Date()) { return "VENCIDA"}
        else { return "POR COBRAR" }
    }
    get collectStatusBadgeColor() {
        if (this.edocStatus === "nulled") { return "red" }
        if (this.collectStatus === "PAID") { return "green"}
        if (this.collectStatus === "PENDING" && !this.editable && this.dueDate !== null && this.dueDate <= new Date()) { return "orange"}
        else { return "blue" }
    }
    get paymentMethodReadable() {
        if (this.paymentMethod === "CASH") { return "Efectivo"}
        if (this.paymentMethod === "TRANSFER") { return "Transferencia"}
        if (this.paymentMethod === "DEBIT_CARD") { return "Tarj. Debito"}
        if (this.paymentMethod === "CREDIT_CARD") { return "Tarj. Credito"}
        else { return "Cheque" }
    }
    get paymentMethodBadgeColor() {
        if (this.paymentMethod === "CASH") { return "green"}
        if (this.paymentMethod === "TRANSFER") { return "blue"}
        else { return "gray" }
    }
    updateItems(item, append=true) {
        if (append) { this.items.unshift(item) }
        else {
            let index = this.getItemIndex(item);
            if (index === -1) { this.items.unshift(item) } 
            else { this.items[index] = item }
        }
        this.updateValues();
     }
    deleteItem(item) {
        let index = this.getItemIndex(item);
        this.items.splice(index, 1);
        if (this.items.length == 0) { this.items = [] }
        this.updateValues();
    }
    getItemIndex(item) {
        var index = -1;
        if (item.uid === null || this.items === null || this.items === undefined) { return -1; }
        for (var i=0; i<this.items.length; i++) {
            if (this.items[i].uid === item.uid) { return i; }
        }
        return index;
    }
    updateValues() {
        var sub0_ = 0.0;
        var sub12_ = 0.0;
        var discount_ = 0.0;
        for (var i=0; i<this.items.length; i++) {
            if (this.items[i].hasTax) {
                sub12_ += this.items[i].totalPrice;
            } else {
                sub0_ += this.items[i].totalPrice;
            }
            discount_ += (this.items[i].amount * this.items[i].unitPrice * this.items[i].discount)
        }
        this.sub0 = sub0_;
        this.sub12 = sub12_;
        this.tax = sub12_ * store.getters.business.tax;
        this.discount = discount_;
        this.total = sub0_ + sub12_ + this.tax;
    }
    loadFromProforma(proformaId) {
        this.id = null;
        this.editable = true;
        this.creditDays = 0;
        this.proformaId = proformaId;
    }
}

export default Sale