const RemisionGuideDestination = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null;
            this.receiverIdentification = "";
            this.receiverName = "";
            this.address = null;
            this.reason = null;
        } else {
            this.id = (object.id === null) ? this.makeid(10) : object.id;
            this.receiverIdentification = object.receiverIdentification;
            this.receiverName = object.receiverName;
            this.address = object.address;
            this.reason = object.reason || null;
        }
    }

    isComplete() {
        console.log(this)
        if (this.receiverName === "") { return "Ingrese un nombre o razón social" }
        else if (this.receiverIdentification === "") { return "Ingrese el número de cédula o RUC" }
        else if (this.address === "" || this.address === null) { return "Ingrese una dirección" }
        else if (this.reason === "" || this.reason === null) { return "Ingrese el motivo de la movilización de los productos/bienes" }
        else { return null }
    }

    makeid(length) {
        var result = '';
        const characters  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

}

export default RemisionGuideDestination
