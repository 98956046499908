const EDocument = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null;
            this.serial = null;
            this.sequence = null;
            this.accessCode = null;
            this.received = null;
            this.authorized = null;
            this.nulled = null;
            this.createdAt = null;
            this.sentAt = null;
            this.authorizedAt = null;
            this.observations = null;
            this.status = null;
        } else {
            this.id = object.id;
            this.serial = object.serial;
            this.sequence = object.sequence;
            this.accessCode = object.accessCode;
            this.received = object.received;
            this.authorized = object.authorized;
            this.nulled = object.nulled;
            this.createdAt = new Date(object.createdAt);
            this.observations = object.observations || null;
            this.status = object.status;

            if (object.sentAt !== undefined && object.sentAt !== null) { this.sentAt = new Date(object.sentAt) }
            else { this.sentAt = null }

            if (object.authorizedAt !== undefined && object.authorizedAt !== null) { this.authorizedAt = new Date(object.authorizedAt) }
            else { this.authorizedAt = null }
        }
    }
}

export default EDocument