import RemisionGuideDestination from "./remisionGuideDestination";
import RemisionGuideItem from "./remisionGuideItem";
import EDocument from "./edocument";

const RemisionGuide = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null;
            this.saleId = null;
            this.saleSerialNumber = null;
            this.saleCustomer = null;
            this.departureAddress = null;
            this.courrierName = null;
            this.courrierIdentification = null;
            this.startDeliveryDate = null;
            this.endDeliveryDate = null;
            this.vehicleIdentification = null;
            this.createdAt = null;
            this.destinations = [];
            this.items = [];
            this.edocStatus = null;
            this.edoc = null;
            this.observations = null;
        } else {
            this.id = object.id || null;
            this.saleId = object.saleId || null;
            this.saleSerialNumber = object.saleSerialNumber || null;
            this.saleCustomer = object.saleCustomer || null;
            this.departureAddress = object.departureAddress || null;
            this.courrierName = object.courrierName || null;
            this.courrierIdentification = object.courrierIdentification || null;
            this.startDeliveryDate = new Date(object.startDeliveryDate);
            this.endDeliveryDate = new Date(object.endDeliveryDate);
            this.vehicleIdentification = object.vehicleIdentification || null;
            this.createdAt = new Date(object.createdAt);
            this.edocStatus = object.edocStatus;
            this.observations = object.observations;

            // Parse edoc
            if (object.edoc !== undefined && object.edoc !== null) {
                this.edoc = new EDocument(object.edoc);
            } else { this.edoc = null }

            // Parse destinations
            if (object.destinations !== null && object.destinations !== undefined && object.destinations.length>0) {
                this.destinations = object.destinations.map(destination => new RemisionGuideDestination(destination));
            } else { this.destinations = [] }

            // Parse items
            if (object.items !== null && object.items !== undefined && object.items.length>0) {
                this.items = object.items.map(item => new RemisionGuideItem(item));
            } else { this.items = [] }
        }
    }

    get createdAtFormatted() {
        const options = { year: 'numeric', month: 'numeric', day: 'numeric'};
        return this.createdAt.toLocaleDateString('es-EC', options) + " - " + this.createdAt.toLocaleTimeString('en-US');
    }

    get edocState() {
        if (this.edocStatus === "none") { return "PENDIENTE" }
        else if (this.edocStatus === "nulled") { return "ANULADA" }
        else if (this.edocStatus === "authorized") { return "AUTORIZADO" }
        else if (this.edocStatus === "received") { return "POR AUTORIZAR" }
        else { return "POR REPORTAR" }
    }
    get edocStateBadgeColor() {
        if (this.editable === true) { return "blue" }
        else if (this.edocStatus === "none") { return "gray" }
        else if (this.edocStatus === "nulled") { return "red" }
        else if (this.edocStatus === "authorized") { return "green" }
        else if (this.edocStatus === "received") { return "yellow" }
        else { return "orange" }
    }

    // Items
    addItem(item) {
        let index = this.getItemIndex(item);
        if (index === -1) { this.items.unshift(item) } 
        else { this.items[index] = item }
    }

    deleteItem(item) {
        let index = this.getItemIndex(item);
        if (index > -1) { this.items.splice(index, 1) }
    }

    getItemIndex(item) {
        if (this.items === null || this.items === undefined) { return -1; }
        for (var i=0; i<this.items.length; i++) {
            if (this.items[i].id === item.id) { return i; }
        }
        return -1;
    }

    // Destination
    addDestination(destination) {
        let index = this.getDestinationIndex(destination);
        if (index === -1) { this.destinations.unshift(destination) } 
        else { this.destinations[index] = destination }
    }

    deleteDestination(destination) {
        let index = this.getDestinationIndex(destination);
        if (index > -1) { this.destinations.splice(index, 1) }
    }

    getDestinationIndex(destination) {
        if (this.destinations === null || this.destinations === undefined) { return -1; }
        for (var i=0; i<this.destinations.length; i++) {
            if (this.destinations[i].id === destination.id) { return i; }
        }
        return -1;
    }
}

export default RemisionGuide
