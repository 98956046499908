const RemisionGuideItem = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null;
            this.description = null;
            this.amount = null;
        } else {
            this.id = object.id || this.makeid(10);
            this.description = object.description;
            this.amount = object.amount;
        }
    }

    makeid(length) {
        var result = '';
        const characters  = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}
export default RemisionGuideItem